// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardAgencyNavbar from "../common/navbar/DashboardAgencyNavbar.res.js";
import * as DashboardAgencyEditForm from "./components/DashboardAgencyEditForm.res.js";

function DashboardAgencyEdit$default(props) {
  var agency = props.agency;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardAgencyNavbar.make, {
                      currentLink: "Edit",
                      agencyId: Caml_option.some(agency.id)
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsx(DashboardAgencyEditForm.make, {
                            agency: agency,
                            tsds: props.tsdSelectIndex.tsds
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var $$default = DashboardAgencyEdit$default;

export {
  Css ,
  $$default as default,
}
/* Container Not a pure module */
