// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Hr from "../../../../../styleguide/components/Hr/Hr.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Api_Agency from "../../../../../api/agencies/Api_Agency.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as DashboardForm from "../../../../../styleguide/dashboard/components/forms/DashboardForm.res.js";
import * as Routes_Agency from "../../../../../routes/common/Routes_Agency.res.js";
import * as ReactDatepicker from "../../../../../bindings/ReactDatepicker.res.js";
import * as ResponseMessage from "../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as ActiveModelError from "../../../../../models/ActiveModelError.res.js";
import * as DashboardFormCss from "../../../../../styleguide/dashboard/components/forms/DashboardFormCss.res.js";
import * as ResourceDocument from "../../../../resources/resource-new/ResourceDocument.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDocumentPdf from "../../../../../styleguide/dashboard/components/documents/pdf/DashboardDocumentPdf.res.js";
import * as IconAdditionalUpload from "../../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "SetUsernameVisible") {
      return {
              message: state.message,
              document: state.document,
              contractResource: state.contractResource,
              messages: state.messages,
              usernameVisible: !state.usernameVisible,
              passwordVisible: state.passwordVisible
            };
    } else {
      return {
              message: state.message,
              document: state.document,
              contractResource: state.contractResource,
              messages: state.messages,
              usernameVisible: state.usernameVisible,
              passwordVisible: !state.passwordVisible
            };
    }
  }
  switch (action.TAG) {
    case "SetMessage" :
        return {
                message: action._0,
                document: state.document,
                contractResource: state.contractResource,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetDocument" :
        return {
                message: state.message,
                document: action._0,
                contractResource: state.contractResource,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetMessages" :
        return {
                message: state.message,
                document: state.document,
                contractResource: state.contractResource,
                messages: action._0,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetContractResource" :
        return {
                message: state.message,
                document: state.document,
                contractResource: action._0,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    
  }
}

var validators_contractStart = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.contractStart;
      if (x !== undefined) {
        return {
                TAG: "Ok",
                _0: Caml_option.valFromOption(x).toString()
              };
      } else {
        return {
                TAG: "Error",
                _0: "Contract Start Date is required"
              };
      }
    })
};

var validators_name = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.name;
      if (x.trim() === "") {
        return {
                TAG: "Error",
                _0: "Name is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.name
              };
      }
    })
};

var validators = {
  tsdId: undefined,
  contractStart: validators_contractStart,
  description: undefined,
  active: undefined,
  name: validators_name
};

function initialFieldsStatuses(_input) {
  return {
          tsdId: "Pristine",
          contractStart: "Pristine",
          description: "Pristine",
          active: "Pristine",
          name: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match_0 = {
    TAG: "Ok",
    _0: input.tsdId
  };
  var match = fieldsStatuses.contractStart;
  var tmp;
  tmp = typeof match !== "object" ? validators.contractStart.validate(input) : match._0;
  var match_0$1 = {
    TAG: "Ok",
    _0: input.description
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.active
  };
  var match$1 = fieldsStatuses.name;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.name.validate(input) : match$1._0;
  var tsdIdResult = match_0;
  var tsdIdResult$1;
  if (tsdIdResult.TAG === "Ok") {
    var contractStartResult = tmp;
    if (contractStartResult.TAG === "Ok") {
      var descriptionResult = match_0$1;
      if (descriptionResult.TAG === "Ok") {
        var activeResult = match_0$2;
        if (activeResult.TAG === "Ok") {
          var nameResult = tmp$1;
          if (nameResult.TAG === "Ok") {
            return {
                    TAG: "Valid",
                    output: {
                      name: nameResult._0,
                      active: activeResult._0,
                      description: descriptionResult._0,
                      contractStart: contractStartResult._0,
                      tsdId: tsdIdResult._0
                    },
                    fieldsStatuses: {
                      tsdId: {
                        TAG: "Dirty",
                        _0: tsdIdResult,
                        _1: "Hidden"
                      },
                      contractStart: {
                        TAG: "Dirty",
                        _0: contractStartResult,
                        _1: "Shown"
                      },
                      description: {
                        TAG: "Dirty",
                        _0: descriptionResult,
                        _1: "Hidden"
                      },
                      active: {
                        TAG: "Dirty",
                        _0: activeResult,
                        _1: "Hidden"
                      },
                      name: {
                        TAG: "Dirty",
                        _0: nameResult,
                        _1: "Shown"
                      }
                    },
                    collectionsStatuses: undefined
                  };
          }
          tsdIdResult$1 = tsdIdResult;
        } else {
          tsdIdResult$1 = tsdIdResult;
        }
      } else {
        tsdIdResult$1 = tsdIdResult;
      }
    } else {
      tsdIdResult$1 = tsdIdResult;
    }
  } else {
    tsdIdResult$1 = tsdIdResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            tsdId: {
              TAG: "Dirty",
              _0: tsdIdResult$1,
              _1: "Hidden"
            },
            contractStart: {
              TAG: "Dirty",
              _0: tmp,
              _1: "Shown"
            },
            description: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            active: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            name: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurTsdIdField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.tsdId, state.fieldsStatuses.tsdId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  tsdId: status,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurContractStartField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractStart, validators_contractStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  tsdId: init.tsdId,
                                  contractStart: status,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDescriptionField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.description, state.fieldsStatuses.description, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  tsdId: init.tsdId,
                                  contractStart: init.contractStart,
                                  description: status,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  tsdId: init.tsdId,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: status,
                                  name: init.name
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNameField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  tsdId: init.tsdId,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: status
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateTsdIdField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.tsdId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            tsdId: status,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateContractStartField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.contractStart, state.submissionStatus, validators_contractStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            tsdId: init.tsdId,
                                            contractStart: status,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDescriptionField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.description, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            tsdId: init.tsdId,
                                            contractStart: init.contractStart,
                                            description: status,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            tsdId: init.tsdId,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: status,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNameField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            tsdId: init.tsdId,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateTsdId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTsdIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateContractStart: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateContractStartField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurTsdId: (function () {
              dispatch("BlurTsdIdField");
            }),
          blurContractStart: (function () {
              dispatch("BlurContractStartField");
            }),
          blurDescription: (function () {
              dispatch("BlurDescriptionField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurName: (function () {
              dispatch("BlurNameField");
            }),
          tsdIdResult: Formality.exposeFieldResult(state.fieldsStatuses.tsdId),
          contractStartResult: Formality.exposeFieldResult(state.fieldsStatuses.contractStart),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.tsdId;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.contractStart;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.description;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.active;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.name;
              if (typeof tmp$4 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var AgencyEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function DashboardAgencyEditForm(props) {
  var agency = props.agency;
  var initialInput = React.useMemo((function () {
          var id = agency.tsdId;
          return {
                  name: agency.name,
                  active: agency.active,
                  description: agency.description,
                  contractStart: Caml_option.some(new Date(agency.contractStart)),
                  tsdId: id !== undefined ? ID.toString(Caml_option.valFromOption(id)) : "0"
                };
        }), []);
  var initialState_contractResource = agency.contractResource;
  var initialState = {
    message: undefined,
    document: undefined,
    contractResource: initialState_contractResource,
    messages: undefined,
    usernameVisible: false,
    passwordVisible: false
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Agency.Dashboard.update(agency.id, output.tsdId, output.name, output.active, output.description, output.contractStart, state.document), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Agency.Dashboard.edit(x._0.id));
                  }
                  var match = x._0;
                  if (typeof match === "object") {
                    var errors = match.VAL;
                    SentryLogger.error1({
                          rootModule: "DashboardAgencyEditForm",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "DashboardAgencyEditForm.make"
                        }, "AgencyCreate::Error", [
                          "Error",
                          errors
                        ]);
                    dispatch({
                          TAG: "SetMessages",
                          _0: errors.activeModelErrors
                        });
                    return cb.notifyOnFailure();
                  }
                  SentryLogger.error1({
                        rootModule: "DashboardAgencyEditForm",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "DashboardAgencyEditForm.make"
                      }, "AgencyCreate::Error", [
                        "Error",
                        "Unknown Error"
                      ]);
                  cb.notifyOnFailure();
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ResponseMessage.make, {
                  messageType: "Error",
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$1 = state.document;
  var match$2 = form.status;
  var tmp;
  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
    tmp = null;
  } else {
    var x = state.messages;
    tmp = JsxRuntime.jsx(ResponseMessage.make, {
          messageType: "Error",
          children: x !== undefined ? JsxRuntime.jsx(ActiveModelError.List.make, {
                  errors: x
                }) : JsxRuntime.jsx("p", {
                  children: "Something went wrong."
                })
        });
  }
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs(H1.make, {
                      className: DashboardFormCss.titleHeader,
                      children: [
                        JsxRuntime.jsx("span", {
                              children: "Edit",
                              className: DashboardFormCss.titleSpan
                            }),
                        agency.name,
                        JsxRuntime.jsx("a", {
                              children: "View",
                              className: DashboardFormCss.showLink,
                              href: Routes_Agency.Dashboard.show(agency.id)
                            })
                      ]
                    }),
                JsxRuntime.jsx(DashboardForm.SectionTitle.make, {
                      children: "General Information"
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "agency--tsdId",
                              className: DashboardFormCss.required,
                              children: "TSD"
                            }),
                        JsxRuntime.jsx(Select.make, {
                              id: "agency--tsdId",
                              value: form.input.tsdId,
                              onChange: (function ($$event) {
                                  form.updateTsdId((function (input, value) {
                                          return {
                                                  name: input.name,
                                                  active: input.active,
                                                  description: input.description,
                                                  contractStart: input.contractStart,
                                                  tsdId: value
                                                };
                                        }), $$event.target.value);
                                }),
                              children: Belt_Array.map(props.tsds, (function (agency) {
                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: ID.toString(agency.id),
                                                  children: agency.name
                                                }, "agency-" + ID.toString(agency.id));
                                    }))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "agency--name",
                              className: DashboardFormCss.required,
                              children: "Name"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "agency--name",
                              value: form.input.name,
                              placeholder: "Add a name for your new agency.",
                              onChange: (function ($$event) {
                                  form.updateName((function (input, value) {
                                          return {
                                                  name: value,
                                                  active: input.active,
                                                  description: input.description,
                                                  contractStart: input.contractStart,
                                                  tsdId: input.tsdId
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.nameResult)
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "agency--description",
                              children: "Description"
                            }),
                        JsxRuntime.jsx(Textarea.make, {
                              id: "agency--description",
                              value: form.input.description,
                              placeholder: "(optional) Add a description.",
                              onChange: (function ($$event) {
                                  form.updateName((function (input, value) {
                                          return {
                                                  name: input.name,
                                                  active: input.active,
                                                  description: value,
                                                  contractStart: input.contractStart,
                                                  tsdId: input.tsdId
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.descriptionResult)
                      ]
                    }),
                JsxRuntime.jsx(Hr.make, {}),
                JsxRuntime.jsx(DashboardForm.SectionTitle.make, {
                      children: "Contract Settings"
                    }),
                JsxRuntime.jsxs(DashboardForm.MultiFieldContainer.make, {
                      children: [
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "agency--contractStart",
                                      className: DashboardFormCss.required,
                                      children: "Contract Start"
                                    }),
                                JsxRuntime.jsx(ReactDatepicker.TextField.make, {
                                      date: Belt_Option.getExn(form.input.contractStart),
                                      onChange: (function (date) {
                                          form.updateContractStart((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: input.active,
                                                          description: input.description,
                                                          contractStart: value,
                                                          tsdId: input.tsdId
                                                        };
                                                }), Caml_option.some(date));
                                        })
                                    }),
                                fieldError(form.contractStartResult)
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      id: form.input.active ? "agency--isActive" : "agency--isInactive",
                                      forId: "agency--active",
                                      className: DashboardFormCss.required,
                                      children: "Contract Active"
                                    }),
                                JsxRuntime.jsx(Toggle.make, {
                                      id: "agencyActiveToggle",
                                      on: form.input.active,
                                      size: "MD",
                                      onChange: (function () {
                                          form.updateActive((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: value,
                                                          description: input.description,
                                                          contractStart: input.contractStart,
                                                          tsdId: input.tsdId
                                                        };
                                                }), !form.input.active);
                                        })
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "agency--contract",
                              children: "Contract"
                            }),
                        match$1 !== undefined ? null : JsxRuntime.jsx(DashboardDocumentPdf.make, {
                                document: state.contractResource,
                                removeDocument: (function (param) {
                                    dispatch({
                                          TAG: "SetContractResource",
                                          _0: undefined
                                        });
                                  }),
                                privateDownload: agency.contractDownload
                              }),
                        JsxRuntime.jsx(ResourceDocument.make, {
                              document: state.document,
                              removeDocument: (function (param) {
                                  dispatch({
                                        TAG: "SetDocument",
                                        _0: undefined
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Uploader.make, {
                              allowed: ["Pdf"],
                              multiple: false,
                              upload: (function (files) {
                                  Belt_Array.map(files, (function (file) {
                                          var match = Uploader.FileType.fromMime(file.type);
                                          if (match === "Pdf") {
                                            return dispatch({
                                                        TAG: "SetDocument",
                                                        _0: Caml_option.some(file)
                                                      });
                                          }
                                          
                                        }));
                                }),
                              setUploadHandler: (function (prim) {
                                  
                                }),
                              children: JsxRuntime.jsxs(Button.make, {
                                    id: "uploadButton",
                                    size: "SM",
                                    color: "Teal",
                                    buttonClassName: DashboardFormCss.uploadDocumentButton,
                                    children: [
                                      JsxRuntime.jsx(IconAdditionalUpload.make, {
                                            size: "MD",
                                            color: "White"
                                          }),
                                      "Upload Contract"
                                    ]
                                  })
                            })
                      ]
                    }),
                tmp,
                JsxRuntime.jsxs(DashboardForm.ButtonContainer.make, {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              id: "submitButton",
                              size: "MD",
                              color: "Primary",
                              disabled: form.submitting,
                              busy: form.submitting,
                              submit: true,
                              children: "Save"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "White",
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  Url.visit(Routes_Agency.Dashboard.index);
                                }),
                              children: "Cancel"
                            })
                      ]
                    })
              ],
              className: DashboardFormCss.container,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var FieldContainer;

var FieldContainer25;

var MultiFieldContainer;

var ButtonContainer;

var SectionTitle;

var make = DashboardAgencyEditForm;

export {
  Css ,
  FieldContainer ,
  FieldContainer25 ,
  MultiFieldContainer ,
  ButtonContainer ,
  SectionTitle ,
  reducer ,
  AgencyEditForm ,
  make ,
}
/* H1 Not a pure module */
